import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/filters'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Auth from "./packages/auth/Auth"
import FlashMessage from '@smartweb/vue-flash-message'

Vue.use(Auth);
Vue.use(VueAxios, axios)

Vue.use(FlashMessage)

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json'
  }
});

const authApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: "Bearer " + Vue.auth.getToken(),
    Accept: 'application/json'
  }
});

const downloadApi = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  responseType: 'blob',
  headers: {
    Authorization: "Bearer " + Vue.auth.getToken(),
  }
});

Vue.prototype.$baseApi = baseApi;
Vue.prototype.$authApi = authApi;
Vue.prototype.$downloadApi = downloadApi;

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.forGuests)) {
    if (Vue.auth.isAuthenticated()) {
      next({
        path: "/"
      });
    } else next();
  } else if (to.matched.some(record => record.meta.forAuth)) {
    if (!Vue.auth.isAuthenticated()) {
      next({ path: "/auth/login" });
    } else {
      if (Vue.auth.isFeatureEnabled(to)) {
        if (to.matched.some(record => record.meta.forAdmin)) {
          if (Vue.auth.isAdmin()) {
            next()
          } else {
            next({
              path: "/"
            });
          }
        } else next();
      } else {
        next({
          path: Vue.auth.getEnabledFeatures()[0]
        })
      }
    }
  } else next();
});
authApi.get('partner-info').then(res => {
  Vue.auth.init({
    currentUser: res.data.data.current_user,
    userType: res.data.data.user_type,
    partnerName: res.data.data.name,
    partnerType: res.data.data.partner_type,
    enabledFeatures: res.data.data.features,
    notificationsCount:res.data.data.notifications_count
  });
}).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}).catch(() => {
  // @TODO
});
